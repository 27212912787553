<template>
  <el-card class="aequAdd">
    <!-- <el-tabs v-model="activeName" type="border-card">
            <el-tab-pane label="单据信息" name="first" :key="'first'"> -->
    <childTab1 />
    <!-- </el-tab-pane>
            <el-tab-pane label="审批流转" name="second" :key="'second'">
                <childTab2 />
            </el-tab-pane>
        </el-tabs> -->
  </el-card>
</template>

<script>
import childTab1 from './TabChild/DequAddMain.vue';
// import childTab2 from './TabChild/DequAddAbout.vue'
export default {
  name: 'dequAdd',
  components: {
    childTab1
    // childTab2
  },
  data() {
    return {
      activeName: 'first'
    };
  }
};
</script>

<style scoped></style>
