<template>
  <div class="vg_button_group">
    <el-button type="primary" @click="submit('cpttForm')" size="small">保存</el-button>
    <el-button @click="cancel" size="small">取消</el-button>

    <span class="vg_tag_position">
      <el-form-item :prop="propInfo.no" :label="propInfo.name" style="margin-right: 50px">
        <el-input
          v-model="formData[propInfo.no]"
          clearable
          size="small"
          maxlength="10"
          show-word-limit
          :placeholder="propInfo.placeholder"
          :disabled="propInfo.disabled"
        ></el-input>
      </el-form-item>
      <span class="vg_tag_label vg_ml_8">状态：</span>
      <el-tag type="info">草拟</el-tag>
    </span>
  </div>
</template>

<script>
export default {
  name: 'addHeader',
  props: {
    formData: {
      type: Object,
      default: () => {}
    },
    propInfo: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    submit() {
      this.$emit('submit');
    },
    cancel() {
      this.$emit('cancel');
    }
  }
};
</script>

<style scoped>
.vg_tag_label {
  line-height: 41px;
}
</style>
