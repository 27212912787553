<template>
  <div class="dequAddMain">
    <el-form :model="formData" :inline="true" :rules="formDataRule" ref="formDataRef" label-width="120px">
      <div class="header_dequ">
        <addHeader
          @submit="submit()"
          @cancel="cancel"
          :formData="formData"
          :propInfo="{ name: '委托设计单号:', no: 'requ_no', placeholder: '根据员工打样编号生成', disabled: true }"
        />
      </div>
      <div class="content_dequ">
        <dequForm :formData="formData" @updateFormData="updateFormData" />
      </div>
      <div>
        <div>
          <span>按钮仅供设计师上传图稿，客户附件请保存后至相关文档上传!</span>
          <div class="flexV vg_mb_8">
            <el-upload
              ref="upload"
              :action="getUploadUrl"
              :before-upload="beforeUpload"
              :data="{ docu_id: 0, dequ_id: 0, docu_names: [] }"
              :on-success="uploadSuccess"
              :show-file-list="true"
              class="flexV"
              multiple
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <span class="vg_ml_8">文件大小不得超过 300MB!</span>
          </div>
          <div>
            <el-table :data="formData.fileData" border>
              <el-table-column type="index" align="center"></el-table-column>
              <el-table-column label="上传时间" prop="create_time" align="center">
                <template v-slot="scope">
                  {{ helper.toTimeDay(scope.row.create_time) }}
                </template>
              </el-table-column>
              <el-table-column label="上传人" prop="dequ_designer_name" align="center"> </el-table-column>
              <el-table-column label="文件名称" prop="docu_name" align="center"></el-table-column>
              <el-table-column label="格式" prop="docu_suffix" align="center"></el-table-column>
              <el-table-column label="稿件等级" prop="dequ_pter_rank" align="center">
                <template v-slot:header="scope"><span class="vg_deep_red">稿件等级</span></template>
                <template v-slot="scope">
                  <el-form-item :prop="'fileData[' + scope.$index + '].dequ_pter_rank'" :rules="[{ required: true }]">
                    <div style="display: flex">
                      <el-select v-model="formData.fileData[scope.$index].dequ_pter_rank" :disabled="dequ_pter_rank_disabled" filterable size="small">
                        <el-option v-for="item in levelList" :key="item.id" :value="item.param1 + ''" :label="item.param1"></el-option>
                      </el-select>
                      <div style="display: flex; flex-direction: column; justify-content: center">
                        <el-tooltip effect="dark" content="复制" placement="top">
                          <el-link
                            :disabled="dequ_pter_rank_disabled"
                            class="vg_cursor"
                            :underline="false"
                            icon="el-icon-document-copy"
                            @click="copyCell(`dequ_pter_rank[copyDataFlag]${scope.row.dequ_pter_rank}[copyDataFlag]${scope.$index}`)"
                          ></el-link>
                        </el-tooltip>
                        <span style="height: 9px"></span>
                        <el-tooltip effect="dark" content="粘贴" placement="right">
                          <el-link
                            :disabled="dequ_pter_rank_disabled"
                            class="vg_cursor"
                            :underline="false"
                            icon="el-icon-finished"
                            @click="pasteCell('dequ_pter_rank', scope.row, scope.$index)"
                          ></el-link>
                        </el-tooltip>
                      </div>
                    </div>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template v-slot="scope">
                  <el-link @click="clickDownload(scope.row)" type="success" class="vg_pointer">下载</el-link>
                  <el-link @click="clickDelete(scope.row, scope.$index)" type="danger" class="vg_ml_8 vg_pointer">删除 </el-link>
                  <el-link v-if="imgarr.indexOf(scope.row.docu_suffix) != -1" class="vg_ml_8 vg_pointer" @click="clickView(scope.row)">查看</el-link>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </el-form>

    <el-row class="vg_mt_16">
      <el-col>
        <inputUser :isCookie="true" ref="userData"></inputUser>
      </el-col>
    </el-row>
    <el-dialog title="图片查看" :visible.sync="dialogVisible" width="50%" class="showImg">
      <el-row class="vd_dis">
        <el-image class="showImg" :src="showImgUrl" fit="contain"></el-image>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import addHeader from '@/views/component/addHeaderInput.vue';
import inputUser from '@/views/component/inputUser';
import dequForm from './DequForm.vue';
import { dequAPI } from '@/api/modules/dequ';
import { get, post } from '../../../../api/request';
import { optnAPI } from '@/api/modules/optn';
import UrlEncode from '@assets/js/UrlEncode';
import { debounce } from 'lodash';

export default {
  name: 'dequAddMain',
  components: {
    addHeader,
    inputUser,
    dequForm
  },
  data() {
    return {
      formData: {
        dequ_id: null,
        dequ_no: '',
        dequ_stff_id: null, //默认就是当前录取人
        cust_id: '',
        dequ_stime: new Date(),
        dequ_petime: null,
        dequ_num: '1',
        dequ_sample: '0',
        dequ_remark: null,
        dequ_brief: null,
        fileData: []
      },
      levelList: [],
      typeList: [],
      dialogVisible: false,
      showImgUrl: '',
      formDataRule: {
        dequ_stff_id: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_id: [{ required: true, message: ' ', trigger: 'blur' }],
        dequ_stime: [{ required: true, message: ' ', trigger: 'blur' }],
        dequ_sample: [{ required: true, message: ' ', trigger: 'blur' }],
        dequ_petime: [{ required: this.$cookies.get('userInfo').dept_id === 16, message: ' ', trigger: 'blur' }],
        dequ_pter_rank: [{ required: true, message: ' ', trigger: 'blur' }]
      },
      imgarr: [
        'bmp',
        'jpg',
        'png',
        'tif',
        'gif',
        'pcx',
        'tga',
        'exif',
        'fpx',
        'svg',
        'psd',
        'cdr',
        'pcd',
        'dxf',
        'ufo',
        'eps',
        'ai',
        'raw',
        'WMF',
        'webp',
        'avif',
        'apng',
        'jpeg'
      ],
      uploadFlag: true,
      beforeFlag: 0,
      successFlag: 0,
      uploadingFiles: []
    };
  },
  created() {
    this.getLevel();
    this.formData.dequ_stff_id = this.$cookies.get('userInfo').stff_id;
    this.formData.dequ_petime = Date.now();
  },
  mounted() {},
  methods: {
    submit() {
      let length = this.formData.fileData.filter(item => !item.dequ_pter_rank).length;
      if (length > 0) {
        this.$message.warning('请把 子表-稿件等级 补充完整');
        return false;
      }
      this.$refs.formDataRef.validate(valid => {
        if (valid) {
          this.$confirm('确认保存?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              this.saveFun();
            })
            .catch(() => {});
        } else {
          console.log('error submit!');
          this.$message.warning('请把必填内容补充完整');
          return false;
        }
      });
    },
    saveFun() {
      const form = {};
      if (this.formData.cust_id) {
        form.cust_id = this.formData.cust_id;
      } else {
        form.cust_id = 0;
      }
      form.dequ_no = this.formData.dequ_no;
      form.dequ_stff_id = this.formData.dequ_stff_id;
      form.dequ_stime = this.helper.toTimeVal(this.formData.dequ_stime);
      if (this.formData.dequ_petime) {
        form.dequ_petime = this.helper.toTimeVal(this.formData.dequ_petime);
      } else {
        form.dequ_petime = 0;
      }
      form.dequ_num = this.formData.dequ_num;
      form.dequ_sample = this.formData.dequ_sample;
      form.dequ_remark = this.formData.dequ_remark;
      if (form.dequ_remark) {
        form.dequ_brief = this.formData.dequ_remark.substring(0, 16);
      }
      form.dequ_pter_list = this.formData.fileData;
      const userInfo = this.$cookies.get('userInfo');
      form.user_id = userInfo.user_id;
      form.stff_id = userInfo.stff_id;
      form.dept_id = userInfo.dept_id;
      form.dept_team_id = userInfo.dept_team_id;
      post(dequAPI.addDequ, form)
        .then(res => {
          if (res.data.code === 0) {
            this.$message.success(res.data.msg);
            this.formData.fileData = [];
            this.$nextTick(() => {
              this.$refs.formDataRef.resetFields();
            });

            const permId = this.$route.query.perm_id;
            this.jump('/dequ_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: permId,
                  form_id: res.data.data.form_id
                })
              )
            });
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.formData.fileData = [];
          this.$nextTick(() => {
            this.$refs.formDataRef.resetFields();
          });
          this.jump('/dequ_list?perm_id=112');
        })
        .catch(() => {});
    },
    updateFormData(val) {
      this.formData.dequ_no = val.dequ_no;
      this.formData.dequ_stff_id = val.dequ_stff_id;
      this.formData.cust_id = val.cust_id;
      this.formData.dequ_remark = val.dequ_remark;
      this.formData.dequ_num = val.dequ_num;
      this.formData.dequ_stime = val.dequ_stime;
      this.formData.dequ_petime = val.dequ_petime;
      this.formData.dequ_sample = val.dequ_sample;
    },
    beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 300;
      if (!isLt5M) {
        this.$message.error('文件大小不得超过 300MB!');
      }
      if (this.formData.fileData.find(x => x.docu_name + x.docu_suffix === file.name)) {
        this.message();
        return false;
      }
      if (this.uploadingFiles.includes(file.name)) {
        this.message();
        return false;
      }
      this.uploadingFiles.push(file.name);
      this.beforeFlag++;
      return isLt5M;
    },
    message: debounce(function () {
      this.$message.error('相同文件不得重复上传！');
    }, 1000),
    uploadSuccess(res) {
      if (res.code === 0) {
        this.formData.fileData.push({
          ...res.data[0],
          dequ_pter_rank: this.$cookies.get('userInfo').dept_id === 12 ? '' : '其他',
          docu_type: '',
          dequ_designer_name: res.data[0].user_name,
          dequ_designer_stff_id: res.data[0].stff_id
        });
      } else {
        this.$message.warning('该文件类型不支持');
      }
      this.successFlag++;
      if (this.successFlag === this.beforeFlag) {
        setTimeout(() => {
          this.$refs.upload.clearFiles(); //去掉文件列表
          this.successFlag = 0;
          this.beforeFlag = 0;
        }, 500);
      }
    },
    getLevel() {
      get(optnAPI.getOptnByPermId, {
        perm_id: 10002
      })
        .then(res => {
          if (res.data.code === 0) {
            this.levelList = res.data.data.form.optn_cntt_list;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });

      get(optnAPI.getOptnByPermId, {
        perm_id: 10001
      })
        .then(res => {
          if (res.data.code === 0) {
            this.typeList = res.data.data.form.optn_cntt_list;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    clickDownload(row) {
      this.$confirm('确定下载?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.docuUrl = this.helper.megPath(row.docu_url);
          this.docuName = row.docu_name + '.' + row.docu_suffix;
          this.helper.downloadItemC(this.docuUrl, this.docuName);
        })
        .catch(() => {});
      // downloadItem
    },
    clickDelete(row, index) {
      this.$confirm('确定删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.formData.fileData.splice(index, 1);
          let uIndex = this.uploadingFiles.indexOf(row.docu_name + '.' + row.docu_suffix);
          uIndex !== -1 ? this.uploadingFiles.splice(uIndex, 1) : '';
        })
        .catch(() => {});
    },
    clickView(row) {
      this.dialogVisible = true;
      this.showImgUrl = this.helper.megPath(row.docu_url);
    },
    pasteCell(column, row, index) {
      navigator.clipboard
        .readText()
        .then(text => {
          let t = text.split('[copyDataFlag]');
          if (t.length > 1 && t[0] === column) {
            if (Number(t[2]) === index) return;
            let rangeIndex;
            if (Number(t[2]) > index) rangeIndex = [index, Number(t[2])];
            else rangeIndex = [Number(t[2]), index];
            this.formData.fileData.forEach((item, tIndex) => {
              if (tIndex >= rangeIndex[0] && tIndex <= rangeIndex[1]) {
                this.$set(item, column, t[1]);
              }
            });
          }
        })
        .catch(err => {
          console.error('无法读取剪贴板: ', err);
        });
    },
    copyCell(text) {
      navigator.clipboard.writeText(text);
    }
  },
  computed: {
    getUploadUrl() {
      return this.helper.modePrepend(dequAPI.uploadDequDocuments);
    },
    dequ_pter_rank_disabled() {
      let { dept_name, stff_id } = this.$cookies.get('userInfo');
      if (stff_id === 1) return false;
      return !['电商部', '设计部'].includes(dept_name);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload {
  width: auto;
  height: auto;
  border: none;
  border-radius: 0;
}
.vd_dis {
  display: flex;
  justify-content: center;
}
</style>
