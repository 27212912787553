<template>
  <div class="dequForm">
    <el-row>
      <!-- <el-col :md="8">
                <el-form-item label="委托单号：" prop="dequ_no">
                    <el-input v-model="formData.dequ_no" clearable size="small" maxlength="10" :disabled="noFlag" show-word-limit>
                    </el-input>
                </el-form-item>
            </el-col> -->
      <el-col :md="8">
        <el-form-item label="委托人：" prop="dequ_stff_id">
          <el-select v-model="formData.dequ_stff_id" clearable size="small" filterable>
            <el-option v-for="item in users" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="客户简称：" prop="cust_id">
          <el-select v-model="formData.cust_id" clearable size="small" filterable>
            <el-option v-for="item in custs" :key="item.cust_id" :label="item.cust_abbr" :value="item.cust_id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="设计数量：" prop="dequ_num">
          <el-input
            v-model="formData.dequ_num"
            size="small"
            clearable
            maxlength="10"
            show-word-limit
            @input="formData.dequ_num = helper.pureNumber(formData.dequ_num)"
          >
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mt_8">
      <el-col :md="16">
        <el-form-item label="设计内容：" prop="dequ_remark" class="w1">
          <el-input v-model="formData.dequ_remark" :autosize="{ minRows: 7, maxRows: 100 }" class="w2" clearable size="small" type="textarea"></el-input>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-row class="vg_mt_8">
          <el-col>
            <el-form-item label="设计开始日：" prop="dequ_stime">
              <el-date-picker v-model="formData.dequ_stime" type="date" size="small" placeholder="选择日期"> </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="vg_mt_8">
          <el-col>
            <el-form-item label="要求交稿时间：" prop="dequ_petime">
              <el-date-picker v-model="formData.dequ_petime" type="date" size="small" placeholder="选择日期"> </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="vg_mt_8">
          <el-col>
            <el-form-item label="提供原样：" prop="dequ_sample">
              <el-radio v-model="formData.dequ_sample" label="1">是</el-radio>
              <el-radio v-model="formData.dequ_sample" label="0">否</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get } from '@/api/request';
import { custAPI } from '@/api/modules/cust';
import { stffAPI } from '../../../../api/modules/staff';

export default {
  name: 'dequForm',
  data() {
    return {
      users: [],
      custs: []
    };
  },
  props: {
    formData: {
      type: Object,
      default() {
        return {};
      },
      require: true
    },
    noFlag: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    formData: {
      handler(newVal, oldVal) {
        this.$emit('updateFormData', newVal);
      },
      deep: true
    }
  },
  created() {
    this.getCusts();
    this.getUsers();
  },
  methods: {
    getCusts() {
      get(custAPI.getAllCustsV1, { status: 2 })
        .then(res => {
          if (res.data.code === 0) {
            this.custs = res.data.data.list;
          }
        })
        .catch(err => {
          this.$message.error(err.data.msg);
        });
    },
    getUsers() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.users = res.data.data;
          }
        })
        .catch(err => {
          this.$message.error(err.data.msg);
        });
    }
  }
};
</script>

<style scoped>
.w1 {
  width: 100%;
}

::v-deep .w1 > .el-form-item__content {
  width: 80%;
}
</style>
